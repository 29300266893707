import { A11yModule } from '@angular/cdk/a11y';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { TimeInputModule } from '@app/shared/time-input/time-input.module';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { TranslateModule } from '@ngx-translate/core';
import { DurationInputControlModule } from '../duration-input-control/duration-input-control.module';
import { TimeEditPopoverComponent } from './time-edit-popover.component';

@NgModule({
  declarations: [TimeEditPopoverComponent],
  imports: [
    CommonModule,
    SatPopoverModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,

    ScrollingModule,
    TranslateModule,
    TimeInputModule,
    DurationInputControlModule,
    A11yModule,
  ],
  exports: [TimeEditPopoverComponent],
})
export class TimeEditPopoverModule {}
