import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { userProjectTaskPerm } from '@app/_helpers/userProjectFind';
import { createRxValue, hasPermissionTaskView } from '@app/_helpers/utils';
import { MaterialScale } from '@app/animations/fade';
import { CreateTaskDialogComponent } from '@app/components/create-task-dialog/create-task-dialog.component';
import { combineLatest } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { firstBy } from 'thenby';
import { Logger, Project, ProjectsQuery, TasksQuery, TasksService, UserSettingsQuery } from 'timeghost-api';

import { ClientProjectPickerDialogComponent } from '../client-project-picker-dialog.component';

export type ClientProjectEntry = {
  selected: boolean;
  task: string;
  preventAutoTasksShow?: boolean;
} & Project;
type ClientProjectFunction = (ev: Event, project: ClientProjectEntry) => void;
const log = new Logger('ClientProjectEntryComponent');
@Component({
  selector: 'tg-client-project-entry',
  templateUrl: './client-project-entry.component.html',
  styleUrls: ['./client-project-entry.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [MaterialScale],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientProjectEntryComponent implements OnInit {
  @Input()
  tooltipTemplate: TemplateRef<any>;
  @ViewChild('defaultTooltip', { static: true, read: TemplateRef })
  defaultTooltip: TemplateRef<any>;
  constructor(
    private userSettinsQuery: UserSettingsQuery,
    private clientProjectDialog: ClientProjectPickerDialogComponent,
    private projectsQuery: ProjectsQuery,
    private tasksQuery: TasksQuery,
    private tasksService: TasksService,
    private dialog: MatDialog,
  ) {}
  tasksLoading = false;
  readonly tasksLoaded = createRxValue(false);
  readonly entry$ = createRxValue<ClientProjectEntry>(null);
  get entry() {
    return this.entry$.value;
  }
  @Input()
  set entry(val: ClientProjectEntry) {
    this.entry$.update(val);
  }

  readonly isPinned$ = combineLatest([this.userSettinsQuery.select(), this.entry$.asObservable()]).pipe(
    map(([user, project]) => {
      return user.pinnedProjects.includes(project.id);
    }),
  );
  readonly tasks$ = combineLatest([
    this.tasksQuery.selectAll(),
    this.entry$.asObservable(),
    this.userSettinsQuery.select(),
  ]).pipe(
    map(([tasks, project, user]) => {
      return tasks
        .filter((x) => x.project && x.project.id === project.id)
        .map((x) => ({
          ...x,
          selected: project.task === x.id,
        }))
        .filter(
          (x) =>
            (!x.completed && hasPermissionTaskView(x, project, user, { public: true })) || (x.completed && x.selected),
        )
        .sort(firstBy('selected', 'desc').thenBy('name', 'asc'));
    }),
  );
  readonly tasks$count = combineLatest([this.entry$.asObservable(), this.tasks$, this.tasksLoaded.asObservable()]).pipe(
    map(([x, tasks, loaded]) => (loaded ? (tasks?.length ?? 0) : null)),
  );
  readonly tasksListHeight = this.tasks$count.pipe(map((x) => (x * 40).clamp(40, 200)));
  readonly showTasks = createRxValue<boolean>(false);
  readonly canCreateTask$ = this.entry$.asObservable().pipe(
    map((entry) => {
      const user = this.userSettinsQuery.getValue();
      return userProjectTaskPerm(user, entry);
    }),
  );

  ngOnInit(): void {
    if (!this.tooltipTemplate) this.tooltipTemplate = this.defaultTooltip;
    if (!this.entry.preventAutoTasksShow && !this.entry.useAsDefault && !this.entry.completed && this.entry.selected) {
      this.toggleTasks();
    }
  }
  @Output()
  selectItem = new EventEmitter();
  @Output()
  selectTaskItem = new EventEmitter();
  @Output()
  contextMenu = new EventEmitter();
  onSelect(ev: Event) {
    ev.preventDefault();
    return this.selectItem.emit([ev, this.entry]);
  }
  onSelectTask(ev: Event, task: Task) {
    ev.preventDefault();
    return this.selectTaskItem.emit([ev, this.entry, task]);
  }
  onContextMenu(ev: Event) {
    ev.preventDefault();
    return this.contextMenu.emit([ev, this.entry]);
  }
  toggleTasks() {
    if (this.tasksLoaded.value) {
      this.showTasks.value = !this.showTasks.value;
      return;
    }
    this.tasksLoading = true;
    this.tasksService
      .getByProject(this.entry)
      .pipe(
        finalize(() => ((this.tasksLoading = false), (this.tasksLoaded.value = true), (this.showTasks.value = true))),
      )
      .subscribe();
  }
  trackId(index: number, { id }: { id: string }) {
    return id;
  }
  createTask(ev: Event) {
    this.dialog.open(CreateTaskDialogComponent, {
      data: {
        project: this.entry,
      },
    });
  }
}
