<div class="flex items-center">
  <div class="z-10 flex items-center space-x-2">
    <ng-container
      *ngTemplateOutlet="
        mode === 'range'
          ? rangeModeTemplate
          : mode === 'range_optional'
            ? stateVisibleMode
              ? rangeModeTemplate
              : durationModeTemplate
            : mode === 'duration'
              ? durationModeTemplate
              : null
      "
    ></ng-container>
    <div
      class="flex h-12 flex-shrink-0 cursor-default items-center rounded-lg px-2.5"
      tabindex="0"
      [class.mat-bg]="!showMenu?.value"
    >
      <div class="flex flex-1 flex-col">
        <ng-container *ngIf="controlValue$.value$ | async as value">
          <div
            class="text-center font-semibold leading-none"
            *ngIf="!mode || mode === 'range' || (mode === 'range_optional' && stateVisibleMode)"
          >
            {{ (mode !== 'duration' ? value.rangeDuration : value.duration) ?? 0 | parseMsAsDuration: false }}
          </div>
          <div class="mat-hint mt-0.5 leading-none" *ngIf="value?.date as date">
            {{ date | formatDate }}
          </div>
        </ng-container>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="mode === 'range_optional' ? modeToggle : null"></ng-container>
  </div>
  <div
    class="mbg-card absolute inset-x-0 top-0 -mx-1.5 -mt-1.5 rounded-lg"
    @matScale
    *ngIf="showMenu?.value"
    tabindex="-1"
  >
    <div class="h-16"></div>
    <mat-divider></mat-divider>
    <div class="flex flex-1 flex-col" tabindex="-1">
      <mat-calendar
        class="-mt-4"
        tabindex="-1"
        [selected]="controlValue.date"
        [startAt]="controlValue.date"
        [dateFilter]="availableDates"
        (selectedChange)="updateDate($event)"
        color="primary"
      ></mat-calendar>
    </div>
    <div class="h-6"></div>
  </div>
</div>
<ng-template #noTimeValue>
  <div>00:00</div>
</ng-template>
<mat-menu #timeContextMenuTimeTemplates="matMenu">
  <ng-template matMenuContent let-data>
    <button mat-menu-item (click)="setMinutesDiff(5, data.prop)">
      {{ 'timeContextMenu.setTo.MinDiffs' | translate: { value: 5 } }}
    </button>
    <button mat-menu-item (click)="setMinutesDiff(10, data.prop)">
      {{ 'timeContextMenu.setTo.MinDiffs' | translate: { value: 10 } }}
    </button>
    <button mat-menu-item (click)="setMinutesDiff(15, data.prop)">
      {{ 'timeContextMenu.setTo.MinDiffs' | translate: { value: 15 } }}
    </button>
    <button mat-menu-item (click)="setMinutesDiff(30, data.prop)">
      {{ 'timeContextMenu.setTo.MinDiffs' | translate: { value: 30 } }}
    </button>
    <button mat-menu-item (click)="setHourDiff(1, data.prop)">
      {{ 'timeContextMenu.setTo.HrDiff' | translate: { value: 1 } }}
    </button>
    <button mat-menu-item (click)="setHourDiff(2, data.prop)">
      {{ 'timeContextMenu.setTo.HrDiffs' | translate: { value: 2 } }}
    </button>
    <button mat-menu-item (click)="setHourDiff(4, data.prop)">
      {{ 'timeContextMenu.setTo.HrDiffs' | translate: { value: 4 } }}
    </button>
    <button mat-menu-item (click)="setWorkDay()">
      {{ 'timeContextMenu.setTo.1workDay' | translate }}
    </button>
  </ng-template>
</mat-menu>
<mat-menu #timeContextMenu="matMenu">
  <ng-template matMenuContent let-data>
    <button mat-menu-item (click)="setToNow(data.prop)">
      {{ 'timeContextMenu.setTo.now' | translate }}
    </button>
    <button mat-menu-item [matMenuTriggerFor]="timeContextMenuTimeTemplates" [matMenuTriggerData]="{ $implicit: data }">
      {{ 'timeContextMenu.setToMore' | translate }}
    </button>
  </ng-template>
</mat-menu>
<div
  style="visibility: hidden; position: fixed"
  [style.left]="timeContextMenuPosition.x"
  [style.top]="timeContextMenuPosition.y"
  #timeContextMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="timeContextMenu"
></div>
<ng-template #startEndTemplate>
  <form
    (submit)="$event.preventDefault()"
    [formGroup]="control"
    #timespanRef
    class="flex flex-row items-center justify-start gap-[10px]"
  >
    <div class="w-[86px] flex-shrink-0 pb-0">
      <app-time-input formControlName="start" [displayFormat]="false" placeholder="00:00">
        <ng-template
          timeInputControl
          let-control="control"
          let-placeholder="placeholder"
          let-inputPlaceholder="inputPlaceholder"
        >
          <mat-form-field
            fxFill
            [matTooltip]="inputPlaceholder | translate"
            appearance="fill"
            class="mat-form-field-no-hint mat-form-field-compact mat-form-field-plain text-center"
          >
            <input type="text" matInput [attr.placeholder]="placeholder" [formControl]="control" />
          </mat-form-field>
        </ng-template>
      </app-time-input>
    </div>
    <mat-icon inline>arrow_forward</mat-icon>
    <div class="w-[86px] flex-shrink-0 pb-0">
      <app-time-input formControlName="end" [displayFormat]="false" placeholder="00:00">
        <ng-template
          timeInputControl
          let-control="control"
          let-placeholder="placeholder"
          let-inputPlaceholder="inputPlaceholder"
        >
          <mat-form-field
            fxFill
            appearance="fill"
            [matTooltip]="inputPlaceholder | translate"
            class="mat-form-field-no-hint mat-form-field-compact mat-form-field-plain text-center"
          >
            <input type="text" matInput [attr.placeholder]="placeholder" [formControl]="control" />
          </mat-form-field>
        </ng-template>
      </app-time-input>
    </div>
  </form>
</ng-template>
<ng-template #rangeModeTemplate>
  <ng-container *ngTemplateOutlet="startEndTemplate"></ng-container>
</ng-template>
<ng-template #rangeOptModeTemplate>
  <ng-container *ngTemplateOutlet="startEndTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="durationModeTemplate"></ng-container>
</ng-template>
<ng-template #durationModeTemplate>
  <form
    (submit)="$event.preventDefault()"
    [formGroup]="control"
    #timespanRef
    class="flex flex-row items-center justify-start gap-[10px]"
  >
    <div class="w-[86px] flex-shrink-0 pb-0">
      <tg-duration-input-control
        formControlName="duration"
        displayFormat="HH:mm"
        placeholder="00:00"
        inputPlaceholder="timer.time.duration"
      >
        <ng-template
          durationInputControl
          let-control="control"
          let-placeholder="placeholder"
          let-inputPlaceholder="inputPlaceholder"
        >
          <mat-form-field
            fxFill
            appearance="fill"
            [matTooltip]="inputPlaceholder | translate"
            class="mat-form-field-no-hint mat-form-field-compact mat-form-field-plain text-center"
          >
            <input type="text" matInput [attr.placeholder]="placeholder" [formControl]="control" />
          </mat-form-field>
        </ng-template>
      </tg-duration-input-control>
    </div>
  </form>
</ng-template>
<ng-template #modeToggle>
  <button
    mat-icon-button
    [matTooltip]="'timer.time.changeInputMode' | translate"
    (click)="toggleStateVisibleMode()"
    type="button"
  >
    <mat-icon>swap_horiz</mat-icon>
  </button>
</ng-template>
